<div class="mainLayoutBody">
 
   
  <div  class="mainLayout" id="mainLayout" >
    
     <app-sideNavbar class="SidNavDiv"></app-sideNavbar>
     <div class="MainDiv">
      <!-- <app-header></app-header> -->
       <router-outlet ></router-outlet>
     </div>
     
   </div>
  
 
 </div>