import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { userNotification } from '@modules/notification';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private NotificationCountSubject = new BehaviorSubject<number>(0);
  public NotificationCount$ = this.NotificationCountSubject.asObservable();
  
// public notificationsCount:number = 0;
public notificationsList:userNotification[] = []
constructor(private http:HttpService) { 
  
}
updateNotificationCount(notificationsCount: number) {
  this.NotificationCountSubject.next(notificationsCount)
}
getNotificationsData(){
  this.http.getReq('api/dashboard/notifications/latest').subscribe({
    next:res=>{
      
      this.updateNotificationCount(res.notification_count)
      // this.notificationsCount = res.notificationsCount;
      this.notificationsList = res.data;
    },
   })
}
}
