// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl:'https://fat-b.restart-technology.com/',
  // frontBaseUrl:'https://fat-f.restart-technology.com',
  // frontBaseUrl:'http://localhost:4200',
  // publishable_api_key:'pk_test_Zy95WYEauz14bJjKWTE51rksYPomES7iJHpVtgLr',

  publishable_api_key:'pk_live_FPZc1VSSZoWZZRR4wizWMn4fQ5wF46NQTkhts51D',
  baseUrl:'https://backdemo.fatorah-pro.com/',
  frontBaseUrl:'https://v2.fatorah-pro.com'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
